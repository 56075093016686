<template>
  <div class="caseProject">
    <a-spin :spinning="spinning">
      <!--  病例状态,标题搜索-->
      <div class="search">
        <div>
          状态: &nbsp;<a-radio-group  @change="openChange" button-style="solid">
          <a-radio-button :value= 1 >
            全部
          </a-radio-button>
          <a-radio-button :value= 2 >
            已启用
          </a-radio-button>
          <a-radio-button :value= 3 >
            已禁用
          </a-radio-button>
        </a-radio-group>
        </div>
        <div style="margin-left: 100px">
          标题:  &nbsp;<a-input v-model="searchName" placeholder="输入病例标题" style="width: 210px" size="default" />
          <a-button style="margin-left: 10px" type="primary" @click="onSearch" >查询</a-button>
          <a-button style="margin-left: 10px" @click="searchCancel" >重置</a-button></div>
      </div>
      <!--  病例项目-->
      <div class="casesAdd">

        <div class="caseShow" v-for="(item,index) in caseList" :key="index">
          <router-link :to="{path: 'case', query: {id: item.id ,title : item.title}}">
            <div class="caseImg">
              <img style="width: 50px;height: 50px" :src="item.pic" alt="">
              &nbsp;&nbsp;<h4 style="overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2; ">
              {{item.title}}</h4>
            </div>
          </router-link>
          <div class="caseData">
            <div class="count">
              <router-link :to="{path: 'case', query: {id: item.id ,title : item.title}}">
                <p style="color: black">总数</p>
                <h4 style="color: dodgerblue">{{item.countSum}}</h4>
              </router-link>
            </div>
            <div class="count">
              <p style="color: black">已通过</p>
              <h4 style="color: rgba(112, 182, 3, 0.647058823529412)">{{ item.countPass }}</h4>
            </div>
            <div class="count">
              <p style="color: black">已驳回</p>
              <h4 style="color:rgba(217, 0, 27, 0.647058823529412)">{{ item.countReject }}</h4>
            </div><div class="count">
            <p style="color: black">待审核</p>
            <h4 style="color: orange">{{ item.countAudit }}</h4>
          </div>
          </div>

          <div class="caseData">
            <div>
              <span v-if="item.number === 0" style="font-size: 12px;color: #CCCCCC">目标/剩余:  无限制</span>
              <span v-else style="font-size: 12px;color: #CCCCCC">目标/剩余: &nbsp; {{ item.number }}/{{item.number -item.countSum}}</span>
            </div>
          </div>
          <a-divider style="margin: 5px 0 0 0 ;height: 1px; padding: 0" />
          <div class="caseBtn">

            <div class="btn" @click="caseCheck(item.id,item.title)">查看病例</div>
            <a-divider type="vertical" />
            <div class="btn" v-if="item.open_range === 'PART'" @click="scopeChange(item.id)">设置范围</div>
            <div class="btn" v-if="item.open_range === 'ALL'" style="color: #cccccc">设置范围</div>

          </div>
        </div>
      </div>
      <div style="width: 100%;height: 50px;float: left;">
        <div style="float: right;margin-right: 20px;margin-top: 15px">
          <a-pagination v-model="Page.current"
                        :total="Page.total"
                        @change="PageChange" />
        </div></div>
      <!---------------------设置范围-------------------------->
      <a-drawer
          title="设置范围"
          width="900"
          :visible="scopeShow"
          @close="closeScope"
      >
        <div class="selectPersonnel" >
          <h3>选择人员</h3>
          <div style="display: flex;align-items: center;width: 50%;justify-content: space-between;margin: 5px 0 5px 0">
            <div> 手机号: &nbsp;<a-input v-model="tels" style="width:160px" placeholder="输入手机号 "/></div>
            <a-button type="primary"  @click="searchTel"> 查询</a-button>
          </div>
          <a-table :columns="columns" :data-source="telList" rowKey="id"   :pagination="false"
          >
        <span slot="operate" slot-scope="text,item">
          <a @click="addPeople(item.id)">选择</a>
        </span>
          </a-table>
        </div>
        <div class="selectedStaff" style="margin: 10px 0 10px 0">
          <div style="display: flex;justify-content: space-between">
            <h3>已选人员</h3>
            <a-button icon="file-excel" style="color: #42b983" @click="excelBtn"> 导出全部</a-button>
          </div>
          <a-table :columns="columns" :data-source="telItems" :rowKey="(record,index)=>{return index}"   :pagination="false"
          >
        <span slot="operate" slot-scope="text,item">
          <a @click="delPeople(item.quence)">删除</a>
        </span>
          </a-table>
          <div style="display: flex;justify-content: flex-end;right: 35px;margin-bottom: 30px;margin-top: 10px">
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          @change="peoplePage" />
          </div>
        </div>
        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="closeScope">
            取消
          </a-button>
          <a-button type="primary" @click="scopeOk">
            确认
          </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>

</template>

<script>
import {
  delPeople,
  getCaseItems,
  getCaseTel,
  getPeopleId,
  postPeople,
  putCaseSwitch
} from "@/service/CaseTemplate_api";

export default {
  name: "caseProgress",
  data(){
    return{
      spinning:false,
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
        },
        {
          title: '医院',
          dataIndex: 'hospital_name',
        },
        {
          title: '科室',
          dataIndex: 'hospital_offices',
        },
        {
          title: '职称',
          dataIndex: 'job_title',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      scopeShow:false,
      searchName:'',
      Page: {
        total:0,
        current: 1,
        pageSize:10,
      },
      caseList:[

      ],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      tels:'',
      telList: [],
      telItems: [],
      caseId:'',
      Ids:'',
      openSearch:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "病历项目进展查询")
  },
  mounted() {
    this.getCaseItem()
  },
  compute(){
  },
  methods:{
    //获取病例项目
    async getCaseItem(pageNum,title,isopen){
      this.spinning = true
      const cases = await getCaseItems(pageNum,title,isopen)
      if (cases.code === 0){
        this.caseList = cases.data.rows
        this.Page.total = cases.data.countSum
      }
      this.spinning =false
    },
    //搜索人员
    async getCaseTels(tel){
      const res = await getCaseTel(tel)
      if (res.code === 0){
        this.telList = res.data
      }
    },
    //分页跳转
    PageChange(){
      this.getCaseItem(this.Page.current,this.searchName,this.openSearch)
    },
    //跳转查看病例
    caseCheck(id,title){
      this.$router.push({ path: `case`,query:{id:id,title:title,key:'1'}})
    },
    //状态搜索
    openChange(e){
      switch (e.target.value){
        case 1:
          this.getCaseItem()
          this.openSearch = ''
          break;
        case 2 :
          this.getCaseItem(1,'',1)
          this.openSearch = 1
          break;
        case 3:
          this.getCaseItem(1,'',0)
          this.openSearch = 0
          break;
      }
    },
    //点击搜索
    onSearch(){
      this.getCaseItem(1,this.searchName)
    },
    //搜索重置
    searchCancel(){
      this.getCaseItem()
      this.searchName = ''
    },
    //搜索人员手机号
    async searchTel(){
      await this.getCaseTels(this.tels)
    },
    //导出选择人员表
    excelBtn(){
      const url = 'https://yun-new-admin-api.yunicu.com/v3/case/excel/' + this.caseId ;
      window.open(url)

    },
    //范围设置
    async scopeChange(id){
      this.caseId = id
      this.scopeShow = true
      await this.getPeople(this.caseId)
    },
    //添加人员
    async addPeople(peopleId){
      this.Ids = peopleId
      console.log(this.Ids)
      const data = {
        dd : peopleId,
        id : this.caseId
      }
      const people = await postPeople(data)
      if (people.code === 0){
        this.$message.success('选择成功')

        await this.getPeople(this.caseId)
      }
    },
    //获取已选择的人员
    async getPeople(id,pageNum){
      const ids = await getPeopleId(id,pageNum)
      if (ids.code ===0){
        this.telItems = ids.data.rows.flat(Infinity)
        this.pagination.total = ids.data.countSum
      }
    },
    //选择人员分页跳转
    peoplePage(){
      this.getPeople(this.caseId,this.pagination.current)
    },
    //删除已选择人员
    async delPeople(e){
      const del =await delPeople(this.caseId,e)
      if (del.code === 0){
        this.$message.success('删除成功')
        const data = {
          id : this.caseId,
          ii : this.caseId
        }
        await this.getPeople(data)
      }
    },
    //添加人员弹窗确认
    scopeOk(){
      this.scopeShow = false
      this.telItems = []
      this.telList = []
      this.tels = ''
      this.pagination.total = 0
    },
    //添加人员弹窗取消
    closeScope(){
      this.scopeShow = false
      this.telItems = []
      this.telList = []
      this.tels = ''
      this.pagination.total = 0
    },
  }
}
</script>

<style lang="scss" scoped>
.caseProject{
  margin: -15px -15px;
  background-color:rgba(240, 242, 245, 1);

  .search{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .casesAdd{
    margin:  auto;
    width: 98%;
    min-height:475px;
    height: auto;
    .caseShow{
      border-radius: 3px;
      border:#DDDDDD  1px solid;
      background-color: white;
      width: 23%;
      float: left;
      height: 187px;
      margin: 1%;
      .caseImg{
        display: flex;
        padding: 10px;
      }
      .caseData{
        display: flex;
        padding:  0 10px;
        margin: 0 auto;
        justify-content: space-between;
        .count p{
          margin: 2px auto;
        }
      }
      .caseBtn{
        cursor: pointer;
        height: 31px;
        display: flex;
        padding: 0 !important;
        background-color:rgba(247, 249, 250, 1);
        justify-content: space-between;
        ::v-deep .ant-divider, .ant-divider-vertical{
          height: auto;
        }
        .btn{
          width:100%;
          display: flex;
          font-size: 11px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
